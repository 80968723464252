import { graphql } from "gatsby"
import React from "react"
import {
  PageContainer,
  PageMain,
  PageSidebar,
  PageFooter,
  PageOuterSidebar,
} from "../assemblies/pageTemplate"
import { PageProps, PageContext } from "../types"
import { Stack } from "../layout/stack"
import { PageHeader, PageTitle, PageMeta } from "../assemblies/pageHeader"
import { SecondaryNav } from "../assemblies/nav"
import { expandFrontmatter } from "../util"

const PageTemplate: React.FC<PageProps<GatsbyTypes.PageQuery, PageContext>> = ({
  data: { markdownRemark },
  pageContext: { parents, children, siblings },
}) => {
  const { title, path, thumbnail } = expandFrontmatter(
    markdownRemark?.frontmatter
  )
  const excerpt = markdownRemark?.excerpt
  const html = markdownRemark?.html || "html error"
  return (
    <PageContainer>
      <PageOuterSidebar>
        <PageSidebar>
          <SecondaryNav
            parents={parents}
            activePath={path}
            siblings={siblings}
            navChildren={children}
          />
        </PageSidebar>
        <PageMain>
          <PageHeader>
            <PageTitle title={title} />
            <PageMeta
              description={excerpt}
              thumbnail={thumbnail?.childImageSharp?.fixed?.src}
            />
          </PageHeader>
          <Stack
            as="article"
            gap={0}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </PageMain>
      </PageOuterSidebar>
      <PageFooter />
    </PageContainer>
  )
}

export default PageTemplate

export const query = graphql`
  query Page($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...MarkdownNode
      tableOfContents(pathToSlugField: "frontmatter.path")
      html
    }
  }
`
